// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_Uw9Y4{align-items:center;justify-content:space-around;transition:all .2s ease-out}.loaderGIF_PCokL{width:60px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "loader_Uw9Y4",
	"loaderGIF": "loaderGIF_PCokL"
};
module.exports = ___CSS_LOADER_EXPORT___;
