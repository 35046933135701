
export default {
  name: "Menu",
  data() {
    return {
      tlMenuOverlay: null,
      tlMenuLinks: null,
      toggleMobileMenu: false,
      pages: {
        data: [
          {id: 1, attributes: {name: 'philosophy', href: '/philosophy'}},
          {id: 2, attributes: {name: 'projects', href: '/projects'}},
          {id: 3, attributes: {name: 'services', href: '/services'}},
          {id: 4, attributes: {name: 'team', href: '/team'}},
          {id: 5, attributes: {name: 'news', href: '/news'}},
          {id: 6, attributes: {name: 'blog', href: '/blog'}},
        ],
      },
      year: null
    };
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
    showNav() {
      return this.$store.state.showNav
    },
    getImageSrc() {
      if (this.$colorMode.value === 'dark' && !this.toggleMobileMenu) {
        return '/icon-lusio_header-white.png';
      } else {
        return '/icon-lusio_header.png';
      }
    },
  },
  watch: {
    '$route'(to, from) {
      this.$store.commit('setShowNav', true)
    }
  },
  mounted() {
    const now = new Date()
    this.year = now.getFullYear()
    this.menuOverlayAnimation();
    this.menuLinksAnimation();
  },
  methods: {
    triggerMobileNavAnimation() {
      this.toggleMobileMenu = !this.toggleMobileMenu;
      if (this.toggleMobileMenu) {
        document.body.classList.add('is-nav-open');

        this.tlMenuOverlay.play().then(() => {
          this.tlMenuLinks.play();
        });
      } else {
        this.tlMenuOverlay.reverse().then(() => {
          this.tlMenuLinks.reverse();
        });
        document.body.classList.remove('is-nav-open');
      }
    },
    menuOverlayAnimation() {
      this.tlMenuOverlay = this.$gsap.timeline({paused: true});

      this.tlMenuOverlay.from(this.$refs['menuOverlay'], {
        x: "-100%",
        duration: 0.5,
        ease: "power3.inOut",
      });
    },
    menuLinksAnimation() {
      this.tlMenuLinks = this.$gsap.timeline({paused: true});

      this.tlMenuLinks.from('.menu-link', {
        y: "120%",
        duration: 0.5,
        ease: "power3.inOut",
        stagger: -0.1
      });
    }
  }
}
