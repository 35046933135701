export const state = () => ({
  loadingAnimationFinished: false,
  previewMode: false,
  isLocomotiveScrollInitialized: false,
  showNav: true,
  // prevLanguage: null
})

export const mutations = {
  finishLoading(state) {
    state.loadingAnimationFinished = true;
  },
  setPreviewMode(state, value) {
    state.previewMode = value;
  },
  setLocomotiveScrollInitialized(state, value) {
    state.isLocomotiveScrollInitialized = value;
  },
  setShowNav(state, bool) {
        state.showNav = bool;
    },
  // setScrollPosition(state, scrollPosition) {
  //   state.scrollPosition = scrollPosition;
  // },
  // setPrevLanguage(state, value) {
  //   state.prevLanguage = value;
  // }
}

export const actions = {}
