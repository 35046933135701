// plugins/swiper.js
import Vue from 'vue';
import Swiper from 'swiper';
import {EffectCards} from 'swiper/modules';


// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/modules/effect-cards.scss';

const swiper = {
  install(Vue, options) {
    Vue.prototype.$swiper = Swiper;
    Vue.prototype.$swiperModules = {
      EffectCards
    };
  }
};

Vue.use(swiper);
