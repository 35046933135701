
import { mapMutations } from 'vuex';

export default {
  name: "Loader",
  data() {
    return {
      gifLoadingTime: process.env.NODE_ENV === 'production' ? 3300 : 0, // ToDo change back to 3300
      domLoaded: false,
      gifAnimationFinished: false,
      gifSrcReady: false,
    }
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
  },
  mounted() {
    // eslint-disable-next-line
    // this.$refs['loaderImg'].src = '/logo-lusio.gif?' + this.timeNow;
    this.gifSrcReady = true;

    if (process.client) { // eslint-disable-line
      if (document.readyState === 'complete') {
        this.domLoaded = true;
        this.triggerAnimationSequence();
      } else {
        window.addEventListener('load', () => {
          this.domLoaded = true;
          this.triggerAnimationSequence();
        });
      }
    }
    setTimeout(() => {
      this.gifAnimationFinished = true;
      this.triggerAnimationSequence();
    }, this.gifLoadingTime);
  },
  methods: {
    ...mapMutations({
      finishLoading: 'finishLoading'
    }),
    triggerAnimationSequence() {
      if (this.gifAnimationFinished && this.domLoaded) {
        // eslint-disable-next-line
        const logo = this.$refs['logo'];

        // ToDo At this point switch the gif to an img so it stops looping

        this.finishLoading();
        // eslint-disable-next-line
        this.$gsap.to(logo,{
          duration: 1,
          opacity: 0,
          // eslint-disable-next-line
          ease: "back.out(1.7)", // Specify an ease
        });
      }
    },
    timeNow() {
      return new Date().getTime()
    },
  }
};
